import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';

import Navigation from './Navigation';
import Hotspots from './Hotspots';

const App = () => {
  const [firstViewVisible, setFirstViewVisible] = useState(true);
  const [secondViewVisible, setSecondViewVisible] = useState(false);
  const [thirdViewVisible, setThirdViewVisible] = useState(false);
  const debounceTimeoutRef = useRef(null);
  // const [footerVisible, setFooterVisible] = useState(false);
  // const [selectedMenu, setSelectedMenu] = useState(null);
  // const [submenuStyle, setSubmenuStyle] = useState({});
  // const [submenuOpen, setSubmenuOpen] = useState(false);
  // const menuRef = useRef(null);

// Original size of the image
// const imageWidth = 1920; // Replace with your image's original width
// const imageHeight = 1214; // Replace with your image's original height
// Desired pixel positions of the hotspots
// const hotspot1Position = { x: 500, y: 500 }; // Replace with your desired pixel position
// const hotspot2Position = { x: 960, y: 540 }; // Replace with your desired pixel position
// const hotspot3Position = { x: 1344, y: 864 }; // Replace with your desired pixel position
// const containerRef = useRef(null);
// const hotspot1Ref = useRef(null);
// const hotspot2Ref = useRef(null);
// const hotspot3Ref = useRef(null);
// const [modalVisible, setModalVisible] = useState(false);
// const [modalImage, setModalImage] = useState('');

  useEffect(() => {
    // console.log(firstViewVisible, secondViewVisible, thirdViewVisible);
  }, [firstViewVisible, secondViewVisible, thirdViewVisible]);

  const handleWheel = (e) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      if (e.deltaY > 0) {
        if (firstViewVisible) {
          setFirstViewVisible(false);
          setSecondViewVisible(true);
        } else if (secondViewVisible) {
          setSecondViewVisible(false);
          setThirdViewVisible(true);
        }
      } else {
        if (thirdViewVisible) {
          setThirdViewVisible(false);
          setSecondViewVisible(true);
        } else if (secondViewVisible) {
          setSecondViewVisible(false);
          setFirstViewVisible(true);
        }
      }
    }, 300); // delay in milliseconds
  };

  // useEffect(() => {
  //   if (thirdViewVisible) {
  //     setFooterVisible(true);
  //   } else {
  //     setFooterVisible(false);
  //     setSelectedMenu(null);
  //   }
  // }, [thirdViewVisible]);

  // const handleMenuClick = (menu, e) => {
  //   e.stopPropagation();
  //   setSelectedMenu(menu);
  //   setSubmenuOpen(true);
  //   if (menuRef.current) {
  //     const buttonWidth = menuRef.current.offsetWidth / ['OVERVIEW', 'NEIGHBOURHOOD', 'RENDERS', 'FLOOR PLANS', 'BUILDER STORYS', 'TEAM', 'VIDEOS'].length;
  //     const leftPosition = e.target.offsetLeft + (e.target.offsetWidth - buttonWidth) / 2;
  //     setSubmenuStyle({
  //       width: `${buttonWidth}px`,
  //       left: `${leftPosition}px`,
  //     });
  //   }
  // };

  // const handleButtonClick = (image) => {
  //   setModalImage(image);
  //   setModalVisible(true);
  // };

  // const handleCloseModal = () => {
  //   setModalVisible(false);
  // };

  // const handleSubmenuClick = (e) => {
  //   e.stopPropagation();
  //   setSubmenuOpen(false);
  // };

  // useEffect(() => {
  //   const closeSubmenu = () => {
  //     setSubmenuOpen(false);
  //   };

  //   const handleResize = () => {
  //     if (containerRef.current && hotspot1Ref.current && hotspot2Ref.current && hotspot3Ref.current) {
  //       const containerWidth = containerRef.current.offsetWidth;
  //       const containerHeight = containerRef.current.offsetHeight;

  //       hotspot1Ref.current.style.top = `${containerHeight * 0.1}px`;
  //       hotspot1Ref.current.style.left = `${containerWidth * 0.2}px`;

  //       hotspot2Ref.current.style.top = `${containerHeight * 0.5}px`;
  //       hotspot2Ref.current.style.left = `${containerWidth * 0.5}px`;

  //       hotspot3Ref.current.style.top = `${containerHeight * 0.8}px`;
  //       hotspot3Ref.current.style.left = `${containerWidth * 0.7}px`;
  //     }
  //   };

  //   window.addEventListener('resize', handleResize);
  //   handleResize();
  
  //   document.addEventListener('click', closeSubmenu);
  
  //   return () => {
  //     document.removeEventListener('click', closeSubmenu);
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  // const container = {
  //   hidden: { opacity: 1 },
  //   show: {
  //     opacity: 1,
  //     transition: {
  //       staggerChildren: 0.3,
  //     },
  //   },
  // };

  // const item = {
  //   hidden: { scale: 0 },
  //   show: { scale: 1, transition: { type: 'spring', stiffness: 500, damping: 30 } },
  // };

  return (
    <div 
      style={{
        width: '100vw', 
        height: '100vh', 
        overflow: 'hidden',
        // backgroundColor: '#3CB4E5',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        position: 'absolute',
        // zIndex: -100,
      }}
      onWheel={handleWheel}
    >

    



      <motion.video
        initial={{ y: 0 }}
        animate={{ y: firstViewVisible ? 0 : '-100vh' }}
        transition={{ duration: 1, ease: 'easeInOut' }}
        style={{ 
          width: '100vw', 
          height: '100vh', 
          objectFit: 'cover', 
          // backgroundColor: 'red', 
          position: 'relative',
          zIndex: 25 
        }}
        src="/assets/VLV Vide 4 secs.mp4"
        autoPlay
        muted
        loop
      /> 

      <motion.div
        // style={{ display: 'flex', overflow: 'hidden', width: '100%', height: '100%' }}
        className='section1'
        initial={{ 
          y: 0,
          // backgroundColor: 'rgba(255, 255, 255, 1)',
        }}
        animate={{ 
          y: secondViewVisible ? '-100vh' : '-100vh', 
          pointerEvents: secondViewVisible ? 'auto' : 'none',
          backgroundColor: thirdViewVisible ? 'rgba(255, 255, 255, 0)' : 'rgba(255, 255, 255, 1)', // Add this line
          opacity: secondViewVisible ? 1 : 0, // Add this line
        }}
        transition={{ 
          duration: 1, 
          ease: 'easeInOut'
         }}
        style={{ 
          width: '100vw', 
          height: '100vh', 
          // backgroundColor: 'rgba(255, 255, 255, 1)',
          // backgroundColor: firstViewVisible || secondViewVisible ? 'white' : null,
          position: 'relative',
          zIndex: 20,
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <motion.div
          animate={{ 
            y: secondViewVisible ? 0 : -50,
          }}
          transition={{ duration: 2, ease: secondViewVisible ? 'easeOut' : 'easeIn' }}
          style={{
            position: 'absolute',
            top: '24px',
            // left: 0, // Add this line
            width: '100vw', // Uncomment this line
            display: 'flex',
            flexDirection: 'column', // Add this line
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '32px',
            color: '#77787b',
            letterSpacing: '0.02em',
            fontFamily: 'Didot-italic',
          }}
        >
           Arriving soon to the shores of St. Maarten. 
          <br/>
          Vie L’Ven luxury resort and residences.
        </motion.div>

        <motion.img
          style={{ 
            width: '30vw', 
            height: '40vh', 
            paddingLeft: '8%',
            paddingRight: '4%',
            objectFit: 'cover', 
            objectPosition: 'center',
          }}
          initial={{ x: 0, width: '30vw', y: 20 }}
          animate={{ 
            x: secondViewVisible ? 0 : '-25vw',
            width: secondViewVisible ? '35vw' : 0,
          }}
          // exit={{ x: '-100vh', y: 50  }}
          transition={{ duration: 2, ease: secondViewVisible ? 'easeOut' : 'easeIn' }}
          src="/assets/left.jpg"
          alt="Image 1"
        />
        {/* <motion.div
  style={{ 
    position: 'relative',
    width: '30vw', 
    height: '40vh', 
    paddingLeft: '8%',
    paddingRight: '4%',
  }}
  initial={{ x: 0, y: 20 }}
  animate={{ 
    x: secondViewVisible ? 0 : '-25vw',
  }}
  transition={{ duration: 2, ease: secondViewVisible ? 'easeOut' : 'easeIn' }}
>
  <motion.img
    style={{ 
      position: 'absolute',
      width: '100%', 
      height: '100%', 
      objectFit: 'cover', 
      objectPosition: 'center',
    }}
    src="/assets/left.jpg"
    alt="Image 1"
  /> */}
  {/* <motion.div
    style={{
      position: 'absolute',
      right: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      // height: '100%',
      padding: '10%',
      color: 'white',
      fontSize: '24px',
      lineHeight: '2.5',
    }}
  >
    <div>FRENCH</div>
    <div>vie</div>
    <div>NOUN</div>
    <div>life</div>
  </motion.div> */}
{/* </motion.div> */}
        <motion.img
          style={{ 
            width: '30vw', 
            height: '40vh', 
            paddingRight: '8%',
            paddingLeft: '4%',
            objectFit: 'cover', 
            objectPosition: 'center',
          }}
          initial={{ x: 0, width: '30vw' }}
          animate={{ 
            x: secondViewVisible ? 0 : '25vw',
            width: secondViewVisible ? '35vw' : 0,

          }}
          transition={{ duration: 2, ease: secondViewVisible ? 'easeOut' : 'easeIn' }}
          src="/assets/right.jpg"
          alt="Image 2"
        />

        
      </motion.div>

      {/* <motion.div
        // style={{ display: 'flex', overflow: 'hidden', width: '100%', height: '100%' }}
        initial={{ y: 0 }}
        animate={{ y: secondViewVisible ? '-100vh' : '-100vh', pointerEvents: secondViewVisible ? 'auto' : 'none'}}
        transition={{ duration: 1, ease: 'easeInOut' }}
        style={{ 
          width: '100vw', 
          height: '100vh', 
          backgroundColor: 'green', 
          
          // objectFit: 'cover', 
          // backgroundImage: 'url(/assets/siteplan_web.jpg)',
          // backgroundSize: 'cover',
          position: 'relative',
          zIndex: 20,
          
        }}
        // src="/assets/siteplan_web.jpg"
        // alt="siteplan_web"
      >
        <motion.img
          style={{ 
            width: '50vw', 
            height: '100vh', 
            objectFit: 'cover', 
            // backgroundColor: 'blue', 
            objectPosition: 'right', 
            // zIndex: 5
          }}
          initial={{ x: '-50%' }}
          animate={{ x: thirdViewVisible ? '-100vh' : 0 }}
          transition={{ duration: 1.3, ease: 'easeIn' }}
          src="/assets/left.jpg"
          alt="Image 1"
        />
        <motion.img
          style={{ 
            width: '50vw', 
            height: '100vh', 
            objectFit: 'cover', 
            // backgroundColor: 'lightblue', 
            objectPosition: 'left', 
            // backgroundPosition: 'right',
            // backgroundPositionX: 'right'
            // zIndex: 5
          }}
          initial={{ x: '50%' }}
          animate={{ x: thirdViewVisible ? '100vh' : 0 }}
          transition={{ duration: 1.3, ease: 'easeIn' }}
          src="/assets/right.jpg"
          alt="Image 2"
        />
      </motion.div> */}


      {/* <div style={{ position: 'relative', width: '100vw', height: '100vh', zIndex: -10 }}>
        <motion.img
          initial={{ y: 0 }}
          animate={{ y: secondViewVisible || thirdViewVisible ? '-200vh' : 0 }}
          transition={{ duration: 1, ease: 'easeInOut' }}
          style={{ 
            width: '100%', 
            height: '100%', 
            objectFit: 'cover', 
          }}
          src="/assets/siteplan_web.jpg"
          alt="siteplan_web"
        />

        <motion.div variants={container} initial="hidden" animate="show">
          <motion.button variants={item} style={{ position: 'absolute', top: `${hotspot1Position.y / imageHeight * 100}%`, left: `${hotspot1Position.x / imageWidth * 100}%` }} onClick={() => handleButtonClick('/assets/Pool Cabanas.png')}>Hotspot 1</motion.button>
          <motion.button variants={item} style={{ position: 'absolute', top: `${hotspot2Position.y / imageHeight * 100}%`, left: `${hotspot2Position.x / imageWidth * 100}%` }} onClick={() => handleButtonClick('/assets/Pool Cabanas.png')}>Hotspot 2</motion.button>
          <motion.button variants={item} style={{ position: 'absolute', top: `${hotspot3Position.y / imageHeight * 100}%`, left: `${hotspot3Position.x / imageWidth * 100}%` }} onClick={() => handleButtonClick('/assets/Pool Cabanas.png')}>Hotspot 3</motion.button>
        </motion.div>
      </div> */}
      { thirdViewVisible ? (
        <>
          <Hotspots visible={{thirdViewVisible}} />
          <Navigation visible={{thirdViewVisible}} />  
        </>
      ) : null}
      
    </div>
  );
};

export default App;